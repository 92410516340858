import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IUserLoginConfiguration } from '../models';
import { utils } from '@kurtosys/ksys-app-template';
import { config } from '../../App/models/config';
import { SetupAssuranceSource } from '../../SetupAssurances/models';

const { isNullOrEmpty } = utils.typeChecks;

export class UserLoginStore extends StoreBase {
	static componentKey: 'userLogin' = 'userLogin';
	@observable public token: string | undefined;
	@observable public username: string = '';
	@observable public password: string = '';

	@computed
	get configuration(): IUserLoginConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(UserLoginStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get backButtonText(): string {
		return (this.configuration && this.configuration.backButtonText) || 'Select Strategy';
	}

	@computed
	get loginButtonText(): string {
		return (this.configuration && this.configuration.loginButtonText) || 'Login';
	}

	@computed
	get usernamePlaceholderText(): string {
		return (this.configuration && this.configuration.usernamePlaceholderText) || 'Username';
	}

	@computed
	get passwordPlaceholderText(): string {
		return (this.configuration && this.configuration.passwordPlaceholderText) || 'Password';
	}

	@computed
	get forgotUsernameButtonText(): string {
		return (this.configuration && this.configuration.forgotUsernameButtonText) || 'Forgot Username?';
	}

	@computed
	get forgotPasswordButtonText(): string {
		return (this.configuration && this.configuration.forgotPasswordButtonText) || 'Forgot Password?';
	}

	@computed
	get registrationText(): string | undefined {
		return (this.configuration && this.configuration.registrationLink && this.configuration.registrationLink.text);
	}

	@computed
	get registrationUrl(): string | undefined {
		const registrationRedirect = (this.configuration && this.configuration.registrationLink && this.configuration.registrationLink.redirectUrl);
		if (registrationRedirect) {
			const redirectUrl = this.getQueryValue(registrationRedirect);
			return redirectUrl;
		}
	}

	@computed
	get errorText(): string {
		return (this.configuration && this.configuration.errorText) || 'The details you provided are invalid, please try again';
	}

	public clear = () => {
		const { assuranceStore } = this.storeContext;
		this.password = '';
		this.username = '';
		assuranceStore.clear();
	}

	public updateUsername = (value: string) => {
		const { messageStore } = this.storeContext;
		this.username = value;
		messageStore.clearAll();
	}

	@action
	public validateUsername = () => {
		if (!this.hasUsername) {
			const { messageStore } = this.storeContext;
			messageStore.setErrorText('Username is required');
			return false;
		}
		return true;
	}

	public updatePassword = (value: string) => {
		const { messageStore } = this.storeContext;
		this.password = value;
		messageStore.clearError();
	}

	@action
	public validatePassword = () => {
		if (isNullOrEmpty(this.password)) {
			const { messageStore } = this.storeContext;
			messageStore.setErrorText('Password is required');
			return false;
		}
		return true;
	}

	@computed
	get hasUsername() {
		return !isNullOrEmpty(this.username);
	}

	public checkUsernameForAssurance = async () => {
		if (this.hasUsername) {
			const { assuranceStore, kurtosysApiStore } = this.storeContext;
			const overrideOptions = {
				body: {
					username: this.username,
				},
			};
			const response = await kurtosysApiStore.assurance.execute(overrideOptions);
			assuranceStore.setAssurance(response);
		}
	}

	public validateUserLogin = async () => {
		if (this.validateUsername()) {
			if (this.validatePassword()) {
				const { appStore, messageStore, kurtosysApiStore, userLogin2FaStore, setupAssurancesStore } = this.storeContext;
				const loadingKey = 'UserLoginStore.validateUserLogin';
				appStore.startLoading(loadingKey);
				const loginRequestBody = {
					username: this.username,
					password: this.password,
				};

				try {
					const overrideOptions = {
						body: loginRequestBody,
					};
					const response = await kurtosysApiStore.login.callApi(overrideOptions);
					if (response.status === 200) {
						this.token = await response.text();
						if (RegExp(/^2fa-/).test(this.token)) {
							userLogin2FaStore.load2FASettings();
							appStore.setStep(config.loginSteps.USER_LOGIN_2FA);
						}
						else {
							let setAssurances: boolean = false;
							if (appStore.isActionEnabled('assurance-setup')) {
								const user = await appStore.getUser();
								if (
									(user.assuranceImageEnabled && !user.assuranceImageSet) ||
									(user.assuranceMessageEnabled && !user.assuranceMessageSet)
								) {
									appStore.setStep(config.loginSteps.SETUP_ASSURANCES);
									setAssurances = true;
									const { setupAssurancesStore } = this.storeContext;
									setupAssurancesStore.source = SetupAssuranceSource.login;
								}
							}
							if (!setAssurances) {
								appStore.redirectTo(appStore.redirectURL);
							}
						}
					}
					else if (response.status === 403) {
						appStore.clear();
						appStore.setStep(config.loginSteps.ACCOUNT_LOCKED);
					}
					else {
						messageStore.setErrorText(this.errorText);
					}
					appStore.stopLoading(loadingKey);
				}
				catch (error) {
					appStore.stopLoading(loadingKey);
					messageStore.setErrorText(this.errorText);
				}
			}
		}
		return false;
	}


	@action
	public onForgotPassword = () => {
		const { appStore, messageStore } = this.storeContext;
		appStore.setStep(config.loginSteps.RESET_PASSWORD);
		messageStore.clearError();
	}

	@action
	public onForgotUsername = () => {
		const { appStore, messageStore } = this.storeContext;
		appStore.setStep(config.loginSteps.FORGOT_USERNAME);
		messageStore.clearError();
	}
}