import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	culture: {
		base: 'Default',
		default: 'en-GB',
	},
	core: {
		serviceUrl: 'https://api-rel.fundpress.io',
		skeletonLoaders: {
			variables: [
				{
					key: 'fillColor',
					value: '#dddddd',
				},
			],
			loaders: [
				{
					id: 'ksys-app-start',
					breakpoints: [
						{
							minWidthInPixels: 0,
							style: {
								height: '86px',
							},
							children: [
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '11.90625px',
									'height': '28px',
									'width': '100%',
								},
								{
									'fill': '{fillColor}',
									'x': '0px',
									'y': '59.8125px',
									'height': '18px',
									'width': '100%',
								},
							],
						},
					],
				},
			],
		},
	},
	components: {
		app: {
			cardProps: {
				props: {},
				queries: {
					title: {
						queryOptionsType: 'property',
						entityType: 'FUND',
						code: 'fund_name',
					},
				},
			},
		},
		userLogin: {
			redirectUrl: {
				queryOptionsType: 'none',
				value: '\\',
			},
		},
		loginStrategySelector: {
			title: {
				queryOptionsType: 'none',
				value: 'Strategy',
			},
		},
	},
};
