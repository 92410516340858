import { StoreContext } from '../../../configuration/StoreContext';
import { KsysApiClient } from '@kurtosys/ksys-api-client/dist/KsysApiClient';
import { observable, reaction, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { GetApplicationAppConfig } from '@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { ListTranslations } from '@kurtosys/ksys-api-client/dist/requests/config/ListTranslations';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { ListCommentaries } from '@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries';
import { ListDisclaimers } from '@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers';
import { SearchDocuments } from '@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments';
import { SearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity';
import { GetTheme } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme';
import { RetrieveClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration';
import { SavedSearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity';
import { RequestStrategies } from '@kurtosys/ksys-api-client/dist/requests/auth/RequestStrategies';
import { Login2FAProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/Login2FAProxy';
import { LoginProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/LoginProxy';
import { Assurance } from '@kurtosys/ksys-api-client/dist/requests/auth/Assurance';
import { RetrieveDocument } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument';
import { Retrieve } from '@kurtosys/ksys-api-client/dist/requests/documents/Retrieve';
import { RetrieveDocumentZip } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip';
import { PasswordReset } from '@kurtosys/ksys-api-client/dist/requests/auth/PasswordReset';
import { UsernameRequest } from '@kurtosys/ksys-api-client/dist/requests/auth/UsernameRequest';
import { UpdatePasswordProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/UpdatePasswordProxy';
import { PasswordRequirements } from '@kurtosys/ksys-api-client/dist/requests/auth/PasswordRequirements';
import { Get2FAMethods } from '@kurtosys/ksys-api-client/dist/requests/auth/Get2FAMethods';
import { ValidateCode } from '@kurtosys/ksys-api-client/dist/requests/auth/ValidateCode';
import { GetUserByToken } from '@kurtosys/ksys-api-client/dist/requests/auth/getUserByToken';
import { AssuranceImages } from '@kurtosys/ksys-api-client/dist/requests/auth/AssuranceImages';
import { AssuranceUpdate } from '@kurtosys/ksys-api-client/dist/requests/auth/AssuranceUpdate';
import { MfaEnroll } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaEnroll';
import { MfaEnrollAuth } from '@kurtosys/ksys-api-client/dist/requests/auth/MfaEnrollAuth';

export class KurtosysApiStore extends StoreBase {
	@observable token: string = '';
	private clientInstance: KsysApiClient | undefined;
	@computed
	get wrappedToken(): { value: string } {
		return { value: this.token };
	}
	@computed
	get options(): IRequestOptions<any> {
		const { appStore } = this.storeContext;
		const serviceUrl = appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl;
		return {
			baseUrl: serviceUrl || this.getBaseAddress(),
		};
	}

	@computed
	get proxyOptions(): IRequestOptions<any> {
		return {
			baseUrl: this.getBaseAddress(),
			disableCachableRequests: true,
		};
	}

	constructor(storeContext: StoreContext) {
		super(storeContext);
	}

	@computed
	get client(): IQueryContextClient {
		const { appStore } = this.storeContext;
		const serviceUrl = appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl;
		const token = this.wrappedToken;
		const options = this.options;
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: new RetrieveClientConfiguration(token, options),
				},
			},
			commentaries: {
				list: new ListCommentaries(token, options),
			},
			disclaimers: {
				list: new ListDisclaimers(token, options),
			},
			documents: {
				search: new SearchDocuments(token, options),
				retrieve: new RetrieveDocument(token, options),
				retrieveByProperty: new Retrieve(token, options),
				retrieveZip: new RetrieveDocumentZip(token, options),
			},
			entity: {
				search: new SearchEntity(token, options),
				savedSearchEntity: new SavedSearchEntity(token, options),
			},
			options: {
				baseUrl: serviceUrl || this.getBaseAddress(),
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return new GetApplicationAppConfig(this.wrappedToken, this.options);
	}

	@computed
	get listTranslations(): ListTranslations {
		return new ListTranslations(this.wrappedToken, this.options);
	}

	@computed
	get getTheme(): GetTheme {
		return new GetTheme(this.wrappedToken, this.options);
	}

	@computed
	get requestStrategies(): RequestStrategies {
		return new RequestStrategies(undefined, this.proxyOptions);
	}

	@computed
	get assurance(): Assurance {
		return new Assurance(undefined, this.proxyOptions);
	}

	@computed
	get assuranceImages(): AssuranceImages {
		return new AssuranceImages(undefined, this.proxyOptions);
	}

	@computed
	get assuranceUpdate(): AssuranceUpdate {
		return new AssuranceUpdate(undefined, this.proxyOptions);
	}

	@computed
	get login(): LoginProxy {
		return new LoginProxy(undefined, this.proxyOptions);
	}

	@computed
	get login2FA(): Login2FAProxy {
		return new Login2FAProxy(undefined, this.proxyOptions);
	}

	@computed
	get passwordReset(): PasswordReset {
		return new PasswordReset(undefined, this.proxyOptions);
	}

	@computed
	get usernameRequest(): UsernameRequest {
		return new UsernameRequest(undefined, this.proxyOptions);
	}

	@computed
	get passwordRequirements(): PasswordRequirements {
		return new PasswordRequirements(undefined, this.proxyOptions);
	}

	@computed
	get validateCode(): ValidateCode {
		return new ValidateCode(undefined, this.proxyOptions);
	}

	@computed
	get updatePassword(): UpdatePasswordProxy {
		return new UpdatePasswordProxy(undefined, this.proxyOptions);
	}

	@computed
	get getUserByToken(): GetUserByToken {
		return new GetUserByToken(undefined, this.proxyOptions);
	}

	get get2FAMethods(): Get2FAMethods {
		return new Get2FAMethods(undefined, this.proxyOptions);
	}

	@computed
	get mfaEnroll(): MfaEnroll {
		return new MfaEnroll(undefined, this.proxyOptions);
	}

	@computed
	get mfaEnrollAuth(): MfaEnrollAuth {
		return new MfaEnrollAuth(undefined, this.proxyOptions);
	}

	@action
	async initialize(): Promise<void> {

	}

	getBaseAddress(suffix: string = 'services/') {
		return this.manifest.getBaseUrl(suffix, this.storeContext.appStore.appParamsHelper);
	}

}